import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import get from 'lodash/get'
import React from 'react'
import links from '../../config/links'
import Button from './Button'

export default function Header({ filter, contain }) {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allContentfulHeader {
        nodes {
          whiteTitle
          orangeTitle
          paragraph {
            raw
          }
          collapsed
          showAppCta
          actionLabel
          actionLink
          image {
            gatsbyImage(layout: CONSTRAINED, width: 1920, height: 1080)
          }
          imageAlt
          page
        }
      }
    }
  `)

  const content = get(data, 'allContentfulHeader.nodes', null)

  const header = content.find((item) => item.page === filter)

  const paddingAmount = header.collapsed
    ? 'px-6 py-16 sm:py-18 lg:px-8 lg:pt-8 lg:pb-20 lg:pr-0'
    : 'px-6 pt-8 sm:py-40 lg:px-8 lg:pt-12 lg:pb-56 lg:pr-0'

  const wrapperTopPadding = header.collapsed ? 'pt-0' : 'pt-16'

  const options = {
    renderMark: {
      [MARKS.BOLD]: (text) => <b className="font-bold">{text}</b>,
    },
    renderNode: {
      [INLINES.HYPERLINK]: (node, children) => {
        const { uri } = node.data
        return (
          <a href={uri} className="underline text-uforange-600">
            {children}
          </a>
        )
      },
      [BLOCKS.HEADING_1]: (node, children) => {
        return <h1 className="font-bold my-4">{children}</h1>
      },
      [BLOCKS.HEADING_2]: (node, children) => {
        return <h2 className="font-bold my-4">{children}</h2>
      },
      [BLOCKS.HEADING_3]: (node, children) => {
        return <h3 className="font-bold my-4">{children}</h3>
      },
      [BLOCKS.PARAGRAPH]: (node, children) => {
        return <span className="my-4 text-white text-lg">{children}</span>
      },
    },
  }

  return (
    <div className="bg-ufblack pt-20">
      <div className="relative">
        <div className="mx-auto max-w-7xl">
          <div
            className={`relative z-10 lg:w-full lg:max-w-2xl ${wrapperTopPadding}`}
          >
            <svg
              className="absolute inset-y-0 right-8 hidden h-full w-80 translate-x-1/2 transform fill-ufblack lg:block"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,0 90,0 50,100 0,100" />
            </svg>

            <div className={`relative ${paddingAmount}`}>
              <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-xl">
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl flex flex-wrap gap-2">
                  <span className="inline">{header.whiteTitle}</span>
                  <span className="inline text-uforange-600">
                    {header.orangeTitle}
                  </span>
                </h1>
                <div className="mt-6 text-lg leading-8 text-white">
                  {header.paragraph?.raw &&
                    renderRichText(header.paragraph, options)}
                </div>
                <div className="mt-6 flex flex-row space-x-2 items-center">
                  {header.actionLabel && header.actionLink && (
                    <Button
                      label={header.actionLabel}
                      href={header.actionLink}
                    />
                  )}
                  {header.showAppCta && (
                    <>
                      <a href={links.appStore} target="_blank" rel="noreferrer">
                        <img
                          src="/images/common/app-store-badge.svg"
                          alt="App Store"
                          className="h-14 cursor-pointer"
                        />
                      </a>
                      <a
                        href={links.googlePlay}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src="/images/common/google-play-badge.png"
                          alt="Play Store"
                          className="h-20 cursor-pointer"
                        />
                      </a>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        {header.image && (
          <div className="bg-gray-50 hidden lg:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 h-full">
            <GatsbyImage
              className={`lg:aspect-auto lg:h-full lg:w-full bg-ufblack`}
              image={header?.image?.gatsbyImage}
              alt={header.imageAlt || header.title}
              objectFit="cover"
            />
          </div>
        )}
      </div>
    </div>
  )
}
