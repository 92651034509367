import React from 'react'

export default function Confirmation() {
  return (
    <div className="bg-uforange-600 py-16">
      <div className="mx-auto max-w-2xl py-16 px-4 text-center sm:py-20 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
          <span className="block">Thank you for your submission</span>
          <span className="block">We'll be in touch shortly.</span>
        </h2>
        <p className="mt-4 text-lg leading-6 text-uforange-200">
          Our ambition is to create the best network for electric vehicle
          charging. Read about our ground breaking products
        </p>
        <a
          href="/about"
          className="mt-8 inline-flex w-full items-center justify-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-uforange-600 hover:bg-uforange-50 sm:w-auto"
        >
          Read more
        </a>
      </div>
    </div>
  )
}
