import React from 'react'
import Header from '../components/common/Header'
import Form from '../components/contact/Form'
import AppCTA from '../components/cta/AppCTA'
import ContactCTA from '../components/cta/ContactCTA'
import Layout from '../components/layout'
import Seo from '../components/seo'
class RootIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo
          title="Contact the Urban Fox Team | Support, enquiries and Feedback "
          description="Talk to the Urban Fox team. We love to hear from you. Get in touch with our sales, support and media teams."
        />
        <Header filter="Contact" />
        <Form />
        <div class="bg-white py-24 sm:py-32">
          <div class="mx-auto max-w-7xl px-6 lg:px-8">
            <div class="mx-auto max-w-2xl space-y-16 divide-y divide-gray-100 lg:mx-0 lg:max-w-none">
              <div class="grid grid-cols-1 gap-x-8 gap-y-4 lg:grid-cols-3">
                <div>
                  <h2 class="text-3xl font-bold tracking-tight text-gray-900">
                    Get in touch
                  </h2>
                  <p class="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                    For anything else, please fill out our contact form and a
                    member of our team will get back to you shortly.
                  </p>
                </div>
                <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                  <div class="rounded-2xl pt-10 px-0 md:px-10">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">
                      Media
                    </h3>
                    <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                      <div>
                        <dt class="sr-only">Email</dt>
                        <dd>
                          <a
                            class="font-semibold text-uforange-600"
                            href="mailto:pressoffice@balfourbeatty.com"
                          >
                            pressoffice@balfourbeatty.com
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="rounded-2xl pt-10 px-0 md:px-10">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">
                      Network support
                    </h3>
                    <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                      <div>
                        <dt class="sr-only">Email</dt>
                        <dd>
                          <a
                            class="font-semibold text-uforange-600"
                            href="mailto:support@urbanfox.network"
                          >
                            support@urbanfox.network
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="rounded-2xl pt-10 px-0 md:px-10">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">
                      Sales
                    </h3>
                    <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                      <div>
                        <dt class="sr-only">Email</dt>
                        <dd>
                          <a
                            class="font-semibold text-uforange-600"
                            href="mailto:sales@urbanfox.network"
                          >
                            sales@urbanfox.network
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div class="rounded-2xl pt-10 px-0 md:px-10">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">
                      General
                    </h3>
                    <dl class="mt-3 space-y-1 text-sm leading-6 text-gray-600">
                      <div>
                        <dt class="sr-only">Email</dt>
                        <dd>
                          <a
                            class="font-semibold text-uforange-600"
                            href="mailto:enquiries@urbanfox.network"
                          >
                            enquiries@urbanfox.network
                          </a>
                        </dd>
                      </div>
                    </dl>
                  </div>
                </div>
              </div>
              <div class="grid grid-cols-1 gap-x-8 gap-y-10 pt-16 lg:grid-cols-3">
                <div>
                  <h2 class="text-3xl font-bold tracking-tight text-gray-900">
                    Locations
                  </h2>
                </div>
                <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:col-span-2 lg:gap-8">
                  <div class="rounded-2xl pt-4 px-0 md:px-10">
                    <h3 class="text-base font-semibold leading-7 text-gray-900">
                      Head Office
                    </h3>
                    <address class="mt-3 space-y-1 text-sm not-italic leading-6 text-gray-600">
                      <p>Q14 Quorum Business Park, Benton Lane, </p>
                      <p>Newcastle Upon Tyne, England, England, NE12 8BU</p>
                      <p>Company Number: 14708495</p>
                    </address>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ContactCTA />
        <AppCTA topPadded />
      </Layout>
    )
  }
}

export default RootIndex
