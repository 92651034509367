const links = {
  appStore: 'https://apps.apple.com/us/app/urban-fox/id6468819158',
  googlePlay: 'https://play.google.com/store/apps/details?id=com.urbanfox.app',
  instagram: 'https://www.instagram.com/urbanfoxev/',
  twitter: 'https://twitter.com/urbanfoxev',
  facebook: 'https://www.facebook.com/urbanfoxev',
  linkedin: 'https://www.linkedin.com/company/urban-fox-ev',
  youtube: 'https://www.youtube.com/channel/UCw8ZyZ7Z2vV5YvKX6E4nW0A',
}

export default links
